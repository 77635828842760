import React from 'react';
import dynamic from 'next/dynamic';
const WithYou = dynamic(() => import('../Hooks/WithYou'), {
    ssr: false,
});
const WeAreWithYou = ({ setIsOpen }) => {
    return (
        <div>
            <WithYou>
                <button onClick={setIsOpen} className=" buttons weare_button orange_button text-blue-new-button-text md:hover:bg-hover-orange xs:w-full md:w-6/12 lg:w-5/12 md:mb-0 xs:mb-20">
                    ¡ADIÓS AL ESTRÉS DE LOS IMPUESTOS!
                </button>
            </WithYou>
        </div>
    );
}

export default WeAreWithYou;